<template>
  <div class="game-landing-page-header">
  </div>
  <div>
    <button @click="goBack" class="go-back mob">
      ← <span style="margin-left: 5px;">Go back</span>
    </button>
    <div class="section">
      <!-- Main content (70% width) -->
      <div class="center-content">
        <!-- Show loader while fetching game data -->
        <div v-if="loading" class="loader">Loading...</div>
        <!-- Show game details once data is fetched -->
        <div style="width: 100%;" v-else>
          <button @click="goBack" class="go-back desktop">
            ← <span style="margin-left: 5px;">Go back</span>
          </button>
          <GameApplicationProposalDetails />
        </div>
      </div>
      <!-- Right-hand sidebar (30% width) - only show once page is loaded -->
      <div v-if="!loading" class="right-sidebar">

        <div class="box">
          <div class="box-header-img"></div>
          <div class="like-box">
            <div v-if="this.selectedGame.status == '3'">
              <div class="title">Love this game? Give it a 'like'!</div>
              <div class="sub-title">
                <div style="display: flex; flex-direction: row; align-items: center; margin-top: 10px; cursor: pointer;">
                  <div class="like-count" style="padding-right: 10px;">{{ likeCount }}</div>
                  <div class="button-heart">
                    <img src="@/assets/images/icons/default-heart-outlined.svg" alt="Heart Icon" @click="handleLike"
                      v-if="!isLiked" />
                    <img src="@/assets/images/icons/active-heart-outlined.svg" alt="Heart Icon" @click="handleLike"
                      v-else />
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <div
                style="display: flex; flex-direction: row; align-items: center; justify-content: space-between; margin-bottom: 20px;">
                <div class="chip">
                  <div v-if="this.selectedGame.status == '5'">
                    {{ this.gameDetails.daysUntilEnd }} days left
                  </div>
                  <div v-else>
                    {{ this.getGameStatus(this.selectedGame) }}
                  </div>
                </div>
                <div>
                  <div style="display: flex; flex-direction: row;">
                    <div class="like-count" style="padding-right: 10px;">{{ likeCount }}</div>
                    <div class="button-heart">
                      <img src="@/assets/images/icons/default-heart-outlined.svg" alt="Heart Icon" @click="handleLike"
                        v-if="!isLiked" />
                      <img src="@/assets/images/icons/active-heart-outlined.svg" alt="Heart Icon" @click="handleLike"
                        v-else />
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="this.selectedGame.status == '5' || this.selectedGame.status == '7'">
                <div style="display: flex; justify-content: flex-start;">
                  <div v-if="this.chartData[2].value == 0">
                    <div class="card-title">{{ this.chartData[0].value }} USDC</div>
                    <div class="card-sub-title">{{ this.chartData[0].title }} </div>
                  </div>
                  <div v-else>
                    <div class="card-title">{{ this.chartData[2].value }} USDC</div>
                    <div class="card-sub-title">{{ this.chartData[2].title }} </div>
                  </div>
                </div>
                <div>
                  <GamePieChart :chartData="chartData" centerText="" />
                </div>
                <div style="display: flex; justify-content: space-between;">
                  <!-- <div style="display: flex; justify-content: flex-end;"> -->
                  <div
                    :style="{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', visibility: isAccount ? 'show' : 'hidden' }">
                    <div class="card-bottom-title">{{ this.userContribution?.contribution }} USDC</div>
                    <div class="card-sub-title">Your contribution</div>
                  </div>
                  <div style="display: flex; flex-direction: column; align-items: flex-end;"
                    v-if="this.chartData[2].value == 0">
                    <div class="card-bottom-title">{{ this.chartData[1].value }} USDC</div>
                    <div class="card-sub-title">{{ this.chartData[1].title }}</div>
                  </div>
                  <div style="display: flex; flex-direction: column; align-items: flex-end;" v-else>
                    <div class="card-bottom-title">{{ this.chartData[0].value }} USDC</div>
                    <div class="card-sub-title">{{ this.chartData[0].title }}</div>
                  </div>
                </div>
              </div>
              <div v-if="this.selectedGame.status == '5'">
                <div class="contribution-title">
                  Contribute to {{ selectedGame.title }}
                  <!-- {{ this.isContributionActive() }} -->
                </div>

                <div class="amount-container" v-if="isContributionActive()">
                  <label for="amount">Amount of USDC</label>
                  <input v-model.number="amount" type="number" id="amount" placeholder="Enter amount" class="amount-input"
                    min="0" @input="validateInput" required />
                  <label style="margin-top: 5px; font-size: 14px; font-weight: 500;" v-if="isAccount">
                    Your balance: {{ usdcBalance }} USDC
                  </label>
                </div>

                <div>
                  <button @click="handleContribution" class="contribute-button"
                    :disabled="!isValidAmount || !isContributionActive() || isLoadingContribution"
                    :class="{ 'disabled-button': !isValidAmount || !isContributionActive() }">
                    <span v-if="isLoadingContribution" class="spinner"></span>
                    <span v-else>
                      Contribute
                    </span>

                  </button>
                  <label style="font-size: 12px; font-weight: 500; color: red; line-height: 1.5;">
                    * only in BSC Network
                  </label>

                </div>
              </div>

            </div>
          </div>
        </div>
        <!-- make this hide in mobile view start -->

        <div class="box" v-if="this.selectedGame.status != '4' && this.selectedGame.status != '3'">
          <div class="like-box">
            <div class="toggle-button" @click="toggleMilestone">
              <button :aria-expanded="isMilestoneOpen">
                {{ isMilestoneOpen ? 'Milestones ' : 'Milestones' }}
              </button>
              <img src="@/assets/images/icons/arrow-button-light.svg" alt="Heart Icon"
                :class="{ 'arrow-up': isMilestoneOpen }" v-if="this.isMobile" />
            </div>
            <div class="milestones-section" v-for="(milestone, index) in this.selectedGame.milestones" :key="milestone.id"
              v-if="isMilestoneOpen || !this.isMobile">
              <div class="milestones-circle" :class="{ 'circle-active': false }">{{ index + 1 }}</div>
              <div class="content">
                <h3 class="milestones-title">{{ milestone.title }}</h3>

                <div class="milestones-html quillWrapper">
                  <div class="quill-container">
                    <div class="ql-editor ql-blank">
                      <div v-html="milestone.description"></div>
                    </div>
                  </div>
                </div>
                <div class="payment-history" v-if="hasMatchingTransaction(milestone.id)">
                  <div style="display: flex; flex-direction: row; justify-content: space-between; "
                    @click="togglePaymentMilestone(milestone.id)">
                    <p>Payment history </p> <img class="payment-arrow" src="@/assets/images/icons/arrow-button-light.svg"
                      alt="arrow Icon" :class="{ 'arrow-up': isPaymentMilestoneOpen(milestone.id) }" />
                  </div>
                  <ul v-if="isPaymentMilestoneOpen(milestone.id)">

                    <div v-for=" transaction, index  in selectedGame.gameMilestonesTransactionModel"
                      :key="transaction.id">
                      <li v-if="transaction.milestonesId === milestone.id && transaction.transactionHash">

                        <span>{{ formatCounter(index + 1) }} Payment - {{ transaction.funds }} USDC</span>
                        <a :href="'https://testnet.bscscan.com/tx/' + transaction.transactionHash" target="_blank"
                          rel="noopener noreferrer" :style="{ color: 'inherit', textDecoration: 'none' }">
                          <img src="@/assets/images/icons/link-icon.svg" alt="Link to transaction" />
                        </a>
                      </li>
                    </div>
                  </ul>
                </div>
                <!-- <div class="payment-history" v-if="hasMatchingTransaction(milestone.id)">
                <p>Payment history</p>
                <ul>
                  <li v-for="transaction in selectedGame.gameMilestonesTransactionModel" :key="transaction.id"
                    v-if="transaction.milestonesId === milestone.id && transaction.transactionHash">
                    <span>{{ transaction.sequence }} payment - {{ transaction.funds }} USDC</span>
                    <a :href="'https://testnet.bscscan.com/tx/' + transaction.transactionHash" target="_blank"
                      rel="noopener noreferrer">
                      <img src="link-icon.svg" alt="Link to transaction" />
                    </a>
                  </li>
                </ul>
              </div> -->
              </div>
            </div>
          </div>
        </div>
        <!-- make this hide in mobile view end -->

      </div>
    </div>
    <!-- Custom Dialog Box -->
    <div v-if="isConnectionDialogVisible" class="dialog-overlay">

      <div class="dialog-box">
        <div style="position:absolute;top: 0; left: 0; z-index: 1; height: 100%; width: 100%;">
          <img style="height: 100%;" src="@/assets/images/game/bg-imgae-dalogueBox.png" />
        </div>
        <div style="display: flex;  justify-content: flex-end; position: relative; ;z-index: 1;">

          <div class="close-button" @click="connectionDialog">
            <img src="../../assets/images/icons/close-button.svg" alt="close-btn">
          </div>
        </div>
        <div class="dialog-content" style="
    margin: 50px 20px;
">

          <p>Please log in to the ATM to continue contribution</p>
          <ConnectButton style="
    margin-top: 40px;
    width: 120px;
    z-index: 2;
" :web3Model=web3Model :authStore=authStore :popUp=connectionDialog></ConnectButton>

        </div>
      </div>
    </div>

    <div v-if="isContributedDialog" class="dialog-overlay">
      <div class="dialog-box">
        <div class="dialog-header">
          <p>Thank you for your contribution!</p>

          <div>

            <div class="close-button" @click="contributedDialog">
              <img src="../../assets/images/icons/close-button.svg" alt="close-btn">
            </div>
          </div>
        </div>
        <div class="dialog-content" style="margin-top: 20px;">

          <p>Don't forget to follow the game and stay updated on its development
            progress</p>

          <div style="
    height: 175px;
    margin: 15px 0;
">

            <!-- <video style="height: 100%;" :src="this.videoLight" autoplay loop muted></video> -->
            <img style="height: 100%;" src="@/assets/images/game/llama.gif" />
            <!-- <div class="lama-gif-container"></div> -->

            <!-- <video style="height: 100%;" src="@/assets/images/game/lama.mp4" autoplay loop muted></video> -->
          </div>
          <h6 class="sub-title">you actually managed to make the llama dance!</h6>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { useRoute, useRouter } from 'vue-router';
import GameApplicationProposalDetails from './gameApplicationProposalDetails.vue';
import { getGameById, gameRating, gameContributed } from "@/api/agfAPI";
import GamePieChart from './gameProposalComponents/gamePieChart.vue';
import { useBlockChainStore } from '@/store/blockchain'
import { formatEther, parseGwei, parseEther } from 'viem';
import ConnectButton from "@/components/common/connectButton.vue"

export default {
  name: 'GameDetails',
  props: {
    web3Model: Object,
    authStore: Object,
    gameId: {
      type: String, // or Number based on how gameId is formatted
      required: true
    }
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
  },
  data() {
    return {
      // gameId: 10,
      isLoadingContribution: false,
      loading: true,
      likeCount: 0, // Like count state
      isLiked: false, // Is the game liked
      amount: null,
      isMilestoneOpen: false,
      openMilestones: {}, // Tracks open state for each milestone
      isMobile: window.innerWidth <= 900,
      usdcBalance: 0,
      userContribution: {
        contribution: '0'
      },
      isValidAmount: 0,
      gameDetails: {

      },
      isContributedDialog: false,
      isConnectionDialogVisible: false,
      videoLight: '@/assets/images/game/lama.mp4', // Path for light mode video
      videoDark: '@/assets/images/game/lama.mp4', // Path for dark mode video
      chartData: [
        {
          "title": "Total contribution",
          "name": "Total contribution",
          "value": 0,
        },
        {
          "title": "Goal",
          "name": "Goal",
          "value": 0,
        },
        {
          "title": "Transferred to the developer",
          "name": "Released",
          "value": 0,
        }
      ]
    };


  },
  components: {
    GameApplicationProposalDetails,
    GamePieChart,
    ConnectButton
  },
  computed: {
    videoSrc() {
      // Return the appropriate video based on the current mode (dark or light)
      return this.$store.state.theme === 'dark' ? this.videoDark : this.videoLight;
    },

    isAccount() {
      const blockChainStore = useBlockChainStore();
      console.log("blockChainStore ", blockChainStore.isLoggedIn())
      if (blockChainStore.isLoggedIn()) {
        this.getBalance('USDC');
      }

      return blockChainStore.isLoggedIn();
    }

  },
  created() {
    this.fetchGameDataById();
    this.setFav();
  },
  mounted() {
    console.log(" mounted  ===>>", this.gameId); // Access gameId here
    this.checkLikeStatus(); // Check if the game is liked when the component is mounted
    // this.getGameDetails(this.gameId);
    // this.getUserContribution(this.gameId);
    if (this.isAccount) {
      this.getBalance('USDC');
    }
  },
  methods: {
    goBack() {
      console.log(" back")
      this.$router.back();  // Goes back to the previous page
    },
    togglePaymentMilestone(milestoneId) {
      if (typeof milestoneId === 'undefined' || milestoneId === null) {
        console.warn('Invalid milestoneId passed to toggleMilestone:', milestoneId);
        return;
      }
      // Directly toggle the milestone's state
      this.openMilestones[milestoneId] = !this.openMilestones[milestoneId];
    },
    isPaymentMilestoneOpen(milestoneId) {
      return !!this.openMilestones[milestoneId]
      // console.log(this.openMilestones[milestoneId])
      // console.log(milestoneId)
      // return true;
    },
    hasMatchingTransaction(milestoneId) {
      return this.selectedGame.gameMilestonesTransactionModel.some(
        (transaction) =>
          transaction.milestonesId === milestoneId &&
          transaction.transactionHash &&
          transaction.transactionHash.trim() !== ""
      );
    },
    formatCounter(counter) {
      // Format the counter as 1st, 2nd, 3rd, etc.
      const suffixes = ["th", "st", "nd", "rd"];
      const value = counter % 100;
      return counter + (suffixes[(value - 20) % 10] || suffixes[value] || suffixes[0]);
    },
    setFav() {
      const likeKey = `liked_game_${this.gameId}`;
      const liked = localStorage.getItem(likeKey);
      console.log(" this is  the set call every time ", liked)

      if (liked === null) {
        // If the item is not found, return false
        this.isLiked = false;
      } else if (liked === 'false') {
        // If the item exists but is 'false', return false
        this.isLiked = false;
      } else {
        // If the item exists and is not 'false', return true
        this.isLiked = true;
      }
      console.log(" this is  the set call   this.isLiked = time ", this.isLiked)

    },
    getGameStatus(game) {
      let type = game.status.toString();
      const listOfStatus = [
        { id: '0', name: 'Inactive' },
        { id: '1', name: 'Under review' },
        { id: '2', name: 'Changes required' },
        { id: '3', name: 'Approved' },
        { id: '4', name: 'Rejected' },
        { id: '5', name: 'Contribution stage' },
        { id: '6', name: 'Released' },
        { id: '7', name: 'In development' }
      ];
      const status = listOfStatus.find(statusItem => statusItem.id === type);
      return status ? status.name : 'Unknown Status';
    },
    connectionDialog() {
      this.isConnectionDialogVisible = !this.isConnectionDialogVisible;
    },
    contributedDialog() {
      this.isContributedDialog = !this.isContributedDialog;
    },


    delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    toggleMilestone() {
      this.isMilestoneOpen = !this.isMilestoneOpen;
    },
    isContributionActive() {
      const totalContribution = parseFloat(this.gameDetails.totalContribution) || 0;
      const contributionNeeded = parseFloat(this.gameDetails.contributionNeeded) || 0;

      // If either value is NaN (not a number), return 'invalid'
      if (isNaN(totalContribution) || isNaN(contributionNeeded)) {
        return 'invalid';
      }

      return totalContribution < contributionNeeded;
    },
    validateInput() {
      // Get the value from the input field

      const value = event.target.value;
      // If the value is negative, set it to zero
      if (!this.isAccount) {
        this.isValidAmount = this.amount
      } else {
        this.isValidAmount = this.amount > 0 && this.amount <= this.usdcBalance;
      }
    },
    // parse to eth
    convertToEth(value) {

      let gwei = value.toString();

      let x = formatEther(gwei).toString();
      return Number.parseFloat(x).toFixed(2);

    },
    async getGameDetails(gameId) {
      try {
        let nftId = this.selectedGame.tokenId;


        const blockChainStore = useBlockChainStore();

        let contract = await blockChainStore.getContract('AGFManager');
        const gameProp = await contract.methods.getGameRecordDetails(nftId).call();
        const gameRecord = {
          gameId: gameId,
          nftId: nftId,
          contributionNeeded: this.convertToEth(gameProp.contributionNeeded),
          contributionReleased: this.convertToEth(gameProp.contributionReleased),
          totalContribution: this.convertToEth(gameProp.totalContribution),
          isActive: gameProp.isActive,
          startTime: new Date(Number(gameProp.startTime) * 1000), // Ensure it's a number and convert to date
          endTime: new Date(Number(gameProp.endTime) * 1000), // Ensure it's a number and convert to date
          daysUntilEnd: Math.ceil((new Date(Number(gameProp.endTime) * 1000) - new Date()) / (1000 * 60 * 60 * 24)), // Calculate days remaining
        };
        this.gameDetails = gameRecord;
        this.chartData = [
          {
            "title": "Total contribution",
            "name": "Total contribution",
            "value": gameRecord.totalContribution ?? 0,
          },
          {
            "title": "Goal",
            "name": "Goal",
            "value": gameRecord.contributionNeeded ?? 0,
          },
          {
            "title": "Transferred to the developer",
            "name": "Released",
            "value": gameRecord.contributionReleased ?? 0,
          },
        ];
      } catch (e) {

      }
    },

    async getUserContribution(gameId) {
      let nftId = this.selectedGame.tokenId

      await this.delay(1000); // Wait for 1 second

      if (this.isAccount) {
        const blockChainStore = useBlockChainStore();

        let contract = await blockChainStore.getContract('AGFManager');

        // Call the smart contract to get user contributions
        const userContribution = await contract.methods.getUserContributionRecords(blockChainStore.getAccount).call();

        // Check if userContribution is valid before accessing its properties
        if (!userContribution || !userContribution.gameContribution || !userContribution.gameIds || !userContribution.gameWithdrawalRecords || !userContribution.totalUserContribution) {
          console.log("Invalid user contribution data");
          return;
        }

        // Convert the response into a more manageable structure
        const contributionRecord = {
          gameContribution: userContribution.gameContribution.map(contribution => this.convertToEth(contribution)), // Convert to Ether
          nftIds: userContribution.gameIds.map(id => Number(id)), // Convert to regular numbers
          gameWithdrawalRecords: userContribution.gameWithdrawalRecords.map(record => this.convertToEth(record)), // Convert to Ether
          totalUserContribution: this.convertToEth(userContribution.totalUserContribution), // Convert to Ether
        };


        // Build the structured array
        const contributionsArray = contributionRecord.nftIds.map((nftId, index) => ({
          nftId: nftId,
          withdrawal: contributionRecord.gameWithdrawalRecords[index], // withdrawal from gameWithdrawalRecords
          contribution: contributionRecord.gameContribution[index], // contribution from gameContribution
        }));

        // Structure the final object
        const result = {
          userTotalContribution: contributionRecord.totalUserContribution,
          contribution: contributionsArray,
        };

        // Ensure getContributionByGameId is called safely
        this.userContribution = this.getContributionByGameId(result, nftId);
        return this.userContribution; // Return the structured result if needed
      } else {
        console.log("Please log in...");
      }
    },
    // New method to get contribution by gameId
    getContributionByGameId(contributions, nftID) {
      console.log('contributionRecord ...==>', contributions);
      console.log(nftID)
      try {
        const contributionDetails = contributions.contribution.find(item => item.nftId.toString() === nftID.toString());
        console.log(contributionDetails)
        if (contributionDetails) {
          return {
            nftId: contributionDetails.nftId,
            withdrawal: contributionDetails.withdrawal,
            contribution: contributionDetails.contribution,
          };
        } else {
          return {
            nftId: contributionDetails.nftId,
            withdrawal: 0,
            contribution: 0,
          }; // Return null if no contribution found for the given gameId
        }
      } catch (e) {

      }
    },
    async getBalance(tokenName) {
      const blockChainStore = useBlockChainStore();
      // await this.delay(3000); // Wait for 2 seconds
      try {



        let contract = await blockChainStore.getTokenContractByName(tokenName);
        const bal = await contract.methods.balanceOf(blockChainStore.getAccount).call();
        this.usdcBalance = this.convertToEth(bal);
      } catch (e) {

      }

    },
    async approveToken(tokenName, approveAddress, amount) {
      try {
        const blockChainStore = useBlockChainStore();

        let contract = await blockChainStore.getTokenContractByName(tokenName);

        return await contract.methods
          .approve(approveAddress, amount)
          .send({
            from: blockChainStore.getAccount,
          })
          .then(() => {
            // resolve();
            return true;
          })
          .catch((e) => {
            return false;
          });
      } catch (e) { }

    },
    async handleContribution() {

      const blockChainStore = useBlockChainStore();

      if (!this.isAccount) {
        localStorage.setItem('redirectData', JSON.stringify({ isRedirected: true, route: '/game' }));

        this.connectionDialog();

      } else if (this.isValidAmount) {
        try {
          this.isLoadingContribution = true;

          const etherString = this.amount.toString(); // Convert to string

          let _gameId = this.gameDetails.nftId;
          let _amount = parseEther(etherString);




          let agfFactory = await blockChainStore.getContract('AGFManager');
          // "0x172b5D9Afd4f411d201621718269A9BeFE9370e2"; 
          const tokenName = 'USDC'; // Replace with your token address
          const approveAddress = blockChainStore.getContractAddress(0, 'AGFManager'); // "0x172b5D9Afd4f411d201621718269A9BeFE9370e2"; // Assuming AGFManager contract address is the one to approve
          const isApproved = await this.approveToken(tokenName, approveAddress, _amount); // Call approveToken first

          if (!isApproved) {
            console.log('Approval failed');
            this.isLoadingContribution = false;
            return; // Exit if approval fails
          }
          let resBlock = await agfFactory
            .methods
            .contributeInGame(_gameId, _amount)
            .send(
              {
                from: blockChainStore.getAccount,
              },
              async (err, hash) => {

              }
            ).then((res) => {
              return {
                isError: false,
                hash: res.transactionHash,
              };
            })
            .catch((e) => {
              return {
                isError: true,
                hash: null,
              };
            });

          if (!resBlock.isError) {
            // this.$toast.success('Contribution successful!');
            let data = {
              "gameId": _gameId,
              "amount": _amount.toString(),
              "txHash": resBlock.hash,
              "chainId": '97',
            }
            this.amount = '';
            let res = await gameContributed(data);
            this.getGameDetails(_gameId);
            this.contributedDialog();
            this.isLoadingContribution = false;

          }
        } catch (e) {
          this.isLoadingContribution = false;

        }
      }
    },
    async fetchGameDataById() {

      const gameId = this.gameId; // Example game ID


      const gameKey = `game_${gameId}`;

      // Check if game data is in localStorage
      const cachedGameData = localStorage.getItem(gameKey);

      if (cachedGameData) {
        // Load from localStorage
        this.selectedGame = JSON.parse(cachedGameData);
        this.likeCount = this.selectedGame.likes;

        this.getGameDetails(this.gameId);
        this.getUserContribution(this.gameId);
        localStorage.setItem('selectedGameProposal', JSON.stringify(this.selectedGame));

        this.loading = false;
      }
      // else {
      // Fetch from API if not in localStorage
      try {
        const response = await getGameById(gameId);
        if (response.isSuccess) {
          localStorage.setItem(gameKey, JSON.stringify(response.data));
          this.selectedGame = response.data;
          this.likeCount = this.selectedGame.likes;

          this.getGameDetails(this.gameId);
          this.getUserContribution(this.gameId);
          localStorage.setItem('selectedGameProposal', JSON.stringify(this.selectedGame));
        }
      } catch (error) {
        console.error('Error fetching game data:', error);
      } finally {
        this.loading = false;
      }
      // }

    },
    async handleLike() {
      if (this.selectedGame.status == '4') {
        return;
      }
      const gameId = this.gameId; // Example game ID
      const likeKey = `liked_game_${gameId}`;
      let data = {
        gameId: gameId,
        status: this.isLiked ? 0 : 1,
        isLiked: !this.isLiked,
      }


      if (localStorage.getItem("token")) {
        console.log(" THIS IS WITH TOKEN ", this.isLiked)
        if (!this.isLiked) {
          console.log(" before if", this.likeCount)
          this.likeCount += 1;
          console.log(" after if", this.likeCount)

          this.selectedGame.likes = this.selectedGame.likes + 1

          this.isLiked = true;

        } else {
          console.log(" before else", this.likeCount)

          this.likeCount -= 1;
          console.log(" after else", this.likeCount)

          this.selectedGame.likes = this.selectedGame.likes + 1

          this.isLiked = false;

        }
        localStorage.setItem(likeKey, this.isLiked);

        await gameRating(data);

        localStorage.setItem(`likeCount_${gameId}`, this.likeCount);

      } else if (!localStorage.getItem(likeKey)) {
        this.likeCount += 1;
        this.selectedGame.likes = this.selectedGame.likes + 1
        this.isLiked = true;
        await gameRating(data);

        // Store the like status and like count in localStorage
        localStorage.setItem(likeKey, true);
        localStorage.setItem(`likeCount_${gameId}`, this.likeCount);
      } else {
        console.log('You have already liked this game.');
        // localStorage.setItem(likeKey, false);

      }
    },
    checkLikeStatus() {
      const gameId = this.gameId; // Example game ID
      const likeKey = `liked_game_${gameId}`;
      const likeCountKey = `likeCount_${gameId}`;

      // Check localStorage for existing like status
      if (localStorage.getItem(likeKey)) {
        // this.isLiked = true;
        this.setFav();
        this.likeCount = parseInt(localStorage.getItem(likeCountKey)) || 0;
      }
    }
  }
}
</script>

<style >
.section {
  display: flex;
  justify-content: space-between;
  margin: 0px auto 150px;
  padding: 50px;

  height: 100%;
  /* Make it full height */
}


.card-title {
  color: var(--main-text, #1C1C1C);
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  align-self: stretch;
}

.card-bottom-title {
  color: var(--main-text, #1C1C1C);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  align-self: stretch;
}

.card-sub-title {
  color: var(--grey-text, #858585);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.like-count {
  color: #1C1C1C;
}

.contribution-title {
  color: var(--main-text, #1C1C1C);
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 20px;
}

.box {
  display: flex;
  margin-bottom: 20px;
  flex-direction: column;
  align-items: center;

  .like-box {
    border-radius: 15px;
    border: 1px solid var(--dark-grey-stroke, #999F9F);
    background: #FFF;
    gap: 40px;
    width: 400px;
    padding: 20px;

  }

  .title {
    color: var(--main-text, #1C1C1C);
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .sub-title {
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: center;
    color: var(--main-text, #1C1C1C);
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;



    .button-heart {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 40px;
      height: 40px;
    }

    .button-heart.liked {
      filter: brightness(0.5) sepia(100%);
      fill: red;
    }
  }

  .box-header-img {
    display: flex;
    width: 237px;
    height: 256px;
    padding: 0.076px 0.137px 0.254px 0.389px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    background-image: url('@/assets/images/game/gamer_character.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}


.center-content {
  flex: 8;
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 100%;

}

.milestones-section {
  display: flex;
  padding-top: 20px;

  .milestones-title {
    color: var(--main-text, #1C1C1C);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .milestones-html {
    color: var(--grey-text, #858585);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 0px;

    .ql-editor ol,
    .ql-editor ul {
      padding-left: 0px;
    }
  }
}



.right-sidebar {
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  margin-left: 20px;
  align-content: flex-start
}

.like-button {
  background-color: #4CAF50;
  color: white;
  font-size: 16px;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.like-button:hover {
  background-color: #45a049;
}

.loader {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

.milestones-circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #D9D9D9;
  display: flex;
  justify-content: center;
  align-items: center;

  margin-right: 10px;
  float: left;
  text-align: center;



  color: var(--main-text, #1C1C1C);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media (max-width: 900px) {


  .section {
    flex-direction: column;
    align-items: center;
    justify-content: center;

  }

  .right-sidebar {
    order: -1;
    /* Move right-sidebar above main-content */
    width: 100%;
  }



  .game-landing-page-header {
    height: 300px;
    /* Adjust height for smaller screens */
    background-size: contain;
    /* Keep the entire image visible on smaller screens */
  }
}

.game-landing-page-header {
  width: 100%;
  height: 400px;
  background-image: url('@/assets/images/game/game_header.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* Ensures the image covers the container */
  margin-top: 100px;
}

.chip {
  padding: 10px 15px;
  background-color: #eeee;
  border-radius: 30px;
  font-size: 18px;
}

@media (max-width: 550px) {
  .box-header-img {
    display: none !important;
  }


}

@media (max-width: 500px) {

  .box {
    margin-bottom: 16px;
  }

  .amount-container {
    font-size: 14px;
    margin-bottom: 16px;
  }

  .contribution-title {
    font-size: 20px;
    margin-bottom: 16px;
  }

  .card-title {
    font-size: 20px;
  }

  .card-bottom-title {
    font-size: 16px;
  }

  .chip {
    font-size: 14px;
    padding: 6px 15px;
  }

  .circle {
    font-size: 16px;
  }

  .box {
    width: 100%;

    .like-box {
      width: 100%;
      padding: 16px;
    }

    .title {
      font-size: 18px;
      text-align: center;
    }

    .sub-title {
      font-size: 18px;
    }
  }

  .game-landing-page-header {
    display: none;
  }

  .section {
    padding: 0px;
  }

  .right-sidebar {
    margin-left: 0px;
  }

  .box-header-img {
    display: none !important;
  }

  .toggle-button {
    font-size: 18px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;


    .arrow-up {
      transform: rotate(180deg);
    }
  }
}



.amount-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  color: #858585;
  font-size: 16px;
}

.amount-input {
  border-radius: 8px;
  border: 1px solid var(--stroke, #EBEBEB);
  display: flex;
  padding: 16px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  background: var(--white, #FFF);
  margin-top: 5px;
  /* Space between label and input */
}

.contribute-button {
  width: 100%;
  display: flex;
  font-size: 18px;
  font-weight: 600;
  padding: 18px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 30px;
  background: #0DA885;
  color: white;
  /* Ensure text color contrasts with background */
  border: none;
  /* Remove default button border */
  cursor: pointer;
  /* Change cursor to pointer on hover */
}

.spinner {
  width: 20px;
  height: 20px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-right: 5px;
  /* Space between spinner and text */
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.disabled-button {
  background-color: gray;
  /* Gray color when disabled */
  cursor: not-allowed;
  /* Shows a "not-allowed" cursor */
  opacity: 0.6;
  /* Optional: Makes it look more disabled */
}

.contribute-button:hover {
  /* background: #0a7e65; */
  /* Optional: Add hover effect */
}

.amount-input {
  /* Existing styles */
  border-radius: 8px;
  border: 1px solid var(--stroke, #EBEBEB);
  display: flex;
  padding: 16px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  background: var(--white, #FFF);
}

/* Hide the spinner buttons in the input field */
.amount-input::-webkit-inner-spin-button,
.amount-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  /* For Chrome, Safari, and Opera */
  margin: 0;
  /* Remove margin */
}

.amount-input {
  -moz-appearance: textfield;
  /* For Firefox */
}



/* Animation styles for sliding effect */
.slide-enter-active,
.slide-leave-active {
  transition: max-height 0.3s ease, opacity 0.3s ease;
}

.slide-enter,
.slide-leave-to

/* .slide-leave-active in <2.1.8 */
  {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  /* Prevents overflow while collapsing */
}

.toggle-button {
  color: var(--main-text, #1C1C1C);
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  .arrow-up {
    transform: rotate(180deg);
  }
}

.milestones-transaction-hash {
  color: var(--Primary-colour, #2EA8AF);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.circle-active {
  background-color: var(--Primary-colour, #2EA8AF);
  color: var(--white-text, #FFF);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/* Add styles for the dialog */
.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;


  @media (max-width: 550px) {
    /* width: 361px; */
    padding: 16px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

}

.dialog-box {
  position: relative;
  /* width: 630px;
  height: 360px; */
  flex-shrink: 0;
  border-radius: 30px;
  background: #FFF;
  display: flex;
  flex-direction: column;
  padding: 30px 20px;

  .close-button {
    filter: invert(78%) sepia(0%) saturate(0%) hue-rotate(208deg) brightness(93%) contrast(89%);

  }

  .dialog-header {
    display: flex;
    text-align: center;
    justify-content: space-between;
    flex-wrap: nowrap;


    p {
      font-size: 28px;
      width: 100%;
      align-items: center;
      font-weight: 400;


    }
  }


  .close-btn {
    width: 24px;
    height: 24px;
    filter: invert(41%) sepia(99%) saturate(746%) hue-rotate(334deg) brightness(91%) contrast(96%);

  }


  .dialog-content {
    p {
      width: 700px;
      font-size: 20px;
    }
  }

  @media (max-width: 550px) {
    width: 361px;
    /* padding: 16px; */
    flex-direction: column;
    /* align-items: end; */
    /* gap: 10px; */

    .dialog-header {
      /* width: 361px; */

      p {
        color: var(--main-text, #1C1C1C);
        text-align: center;
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .dialog-content {
      p {
        width: 300px;
        color: var(--main-text, #1C1C1C);
        text-align: center;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 19px;
      }
    }

  }
}

.dialog-content {
  color: #000;
  display: flex;

  justify-content: center;
  flex-wrap: nowrap;

  p {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .sub-title {
    color: var(--grey-text, #858585);
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
}

/* .lama-gif-container{
  height: 100%;  
  width: 100%;  
  background-image: url('http://localhost:8080/images/agf/llama-dark.gif');
  background-repeat: no-repeat;  
  background-size: cover;      
  background-position: center; 
} */

.payment-history {
  /* background-color: #f9f9f9; */
  padding: 1rem;
  border-radius: 8px;

  .arrow-up {
    transform: rotate(180deg);
  }
}

.payment-history p {
  /* font-weight: bold; */
  margin: 0 0 0.5rem;
  color: #4E4E4E;
}

.payment-history ul {
  list-style: none;
  padding: 0;
  margin: 0;
  color: #4E4E4E;
}

.payment-history li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0;
  /* border-bottom: 1px solid #e0e0e0; */
}

.payment-history li:last-child {
  border-bottom: none;
}

.payment-history a img {
  width: 16px;
  height: 16px;
}

.go-back {
  /* position: absolute;
  top: 10px;
  left: 10px; */
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.desktop {
  @media (max-width: 550px) {
    display: none;
  }
}

.mob {
  display: none;

  @media (max-width: 550px) {
    /* display:flex; */
    display: block;
    margin-top: 100px;
    margin-left: 20px;
    /* padding: 10px; */
  }
}
</style>
